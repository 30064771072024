"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var DashboardGridComponent = /** @class */ (function () {
    function DashboardGridComponent(store) {
        this.store = store;
        this.projects = [];
        this.headers = true;
        this.sorting = { by: '', direction: 'asc' };
        this.extended = false;
        this.sort = new core_1.EventEmitter();
        this.integration$ = this.store.pipe(rx_1.choose(function (x) { return x.integration; }));
        this.fakeStats = [
            { type: 'next', title: 'New', duration: '---' },
            { type: 'next', title: 'Vetted', duration: '---' },
            { type: 'next', title: 'Assigned', duration: '---' },
            { type: 'next', title: 'Negotiating', duration: '---' },
            { type: 'next', title: 'Approving', duration: '---' },
            { type: 'next', title: 'Executing', duration: '---' },
            { type: 'next', title: 'Executed', duration: null },
        ];
    }
    DashboardGridComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.integration$.subscribe(function (integration) {
            _this.integration = integration;
        });
    };
    Object.defineProperty(DashboardGridComponent.prototype, "icon", {
        /**
         * Sorting.
         */
        get: function () {
            return this.sorting.direction == 'asc' ? 'arrow-up' : 'arrow-down';
        },
        enumerable: true,
        configurable: true
    });
    DashboardGridComponent.prototype.visibility = function (field) {
        return this.sorting.by == field ? 'visible' : 'hidden';
    };
    DashboardGridComponent.prototype.team = function (project, role) {
        var member = project.team.singular[role];
        return member ? member.user : null;
    };
    return DashboardGridComponent;
}());
exports.DashboardGridComponent = DashboardGridComponent;
