"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var effects_1 = require("@ngrx/effects");
var operators_1 = require("rxjs/operators");
var routes_1 = require("@utils/routes");
var auth_1 = require("@utils/auth");
var store_1 = require("@ngrx/store");
var rx_1 = require("@utils/rx");
var rxjs_1 = require("rxjs");
var url_1 = require("@utils/url");
var http_1 = require("@angular/common/http");
var Response_1 = require("@utils/Response");
var config_1 = require("@utils/config");
var array_1 = require("@utils/array");
var app_reducer_1 = require("./app.reducer");
var AppEffects = /** @class */ (function () {
    function AppEffects(actions$, store, http) {
        var _this = this;
        this.actions$ = actions$;
        this.store = store;
        this.http = http;
        this.translationsLoad$ = this.actions$.pipe(rx_1.ofType('TRANSLATIONS_LOAD'), operators_1.mergeMap(function () {
            return config_1.config('translations_url')
                ? _this.http.get(config_1.config('translations_url'))
                : rxjs_1.of({ data: {} });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new app_reducer_1.TranslationsLoaded(payload);
        }));
        this.configurationLoaded$ = this.actions$.pipe(rx_1.ofType('CONFIGURATION_LOADED'), operators_1.mergeMap(function (_a) {
            var payload = _a.payload;
            return [
                new app_reducer_1.StatesLoaded(payload.states),
                new app_reducer_1.RolesLoaded(payload.roles),
                new app_reducer_1.SignedInLoaded(payload.user),
                new app_reducer_1.TemplateLoaded(payload.fields),
                new app_reducer_1.NavbarLoaded(payload.navbar),
                new app_reducer_1.KindsLoaded(payload.kinds),
                new app_reducer_1.TimezonesLoaded(payload.timezones),
                new app_reducer_1.IntegrationLoaded(payload.integration),
            ];
        }));
        this.logout$ = this.actions$.pipe(rx_1.ofType('LOGOUT'), operators_1.delayWhen(function () { return _this.http.delete(routes_1.AUTH_LOGOUT_URL()); }), operators_1.tap(function () { return auth_1.clearToken(); }), operators_1.map(function () { return ({ type: 'RESET' }); }));
        /**
         * The Good, the Bad and the UGLY
         */
        this.createExport$ = this.actions$.pipe(rx_1.ofType('EXPORT_CREATE'), operators_1.mergeMap(function (_a) {
            var params = _a.payload;
            return _this.http.post(url_1.make(routes_1.PROJECT_EXPORTS_URL(), params), {});
        }));
        this.reportsLoad$ = this.actions$.pipe(rx_1.ofType('REPORTS_LOAD'), operators_1.mergeMap(function () { return _this.store.pipe(rx_1.once(function (x) { return x.reports; })); }), operators_1.mergeMap(function (reports) {
            return array_1.notEmpty(reports)
                ? rxjs_1.of(reports)
                : _this.http.get(routes_1.REPORTS_URL()).pipe(operators_1.map(function (x) { return x.data; }));
        }), operators_1.map(function (payload) { return new app_reducer_1.ReportsLoaded(payload); }));
        this.searchLoad$ = this.actions$.pipe(rx_1.ofType('SEARCH_LOAD'), operators_1.switchMap(function (_a) {
            var params = _a.payload;
            return _this.http.get(routes_1.PROJECTS_URL(), { params: params });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new app_reducer_1.SearchLoaded(payload);
        }));
        this.auditsLoad$ = this.actions$.pipe(rx_1.ofType('AUDITS_LOAD'), operators_1.switchMap(function (_a) {
            var params = _a.payload;
            return _this.http.get(routes_1.AUDITS_URL(), {
                params: params.phrase ? { phrase: params.phrase } : {},
            });
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new app_reducer_1.AuditsLoaded(payload);
        }));
        this.dashboardLoad$ = this.actions$.pipe(rx_1.ofType('DASHBOARD_LOAD'), operators_1.switchMap(function (_a) {
            var params = _a.payload;
            return _this.http.get(routes_1.PROJECTS_URL(), { params: params });
        }), Response_1.pagination(), operators_1.map(function (payload) { return new app_reducer_1.DashboardLoaded(payload); }));
        this.dashboardProjectTagAttach$ = this.actions$.pipe(rx_1.ofType('DASHBOARD_PROJECT_TAG_ATTACH'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, project = _b.project, tag = _b.tag;
            return _this.http.post(project.url + "/tags/" + tag.id, {});
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new app_reducer_1.DashboardProjectUpdated(payload);
        }));
        this.dashboardProjectTagDetach$ = this.actions$.pipe(rx_1.ofType('DASHBOARD_PROJECT_TAG_DETACH'), operators_1.mergeMap(function (_a) {
            var _b = _a.payload, project = _b.project, tag = _b.tag;
            return _this.http.delete(project.url + "/tags/" + tag.id, {});
        }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new app_reducer_1.DashboardProjectUpdated(payload);
        }));
        this.calendarLoad$ = this.actions$.pipe(rx_1.ofType('CALENDAR_LOAD'), operators_1.mergeMap(function () { return _this.http.get(routes_1.CALENDAR_URL()); }), operators_1.map(function (_a) {
            var payload = _a.data;
            return new app_reducer_1.CalendarLoaded(payload);
        }));
        this.reportDetailLoad$ = this.actions$.pipe(rx_1.ofType('REPORT_DETAIL_LOAD'), operators_1.switchMap(function (_a) {
            var params = _a.payload;
            return _this.http.get(url_1.api('/reports/projects'), { params: params });
        }), Response_1.pagination(), operators_1.map(function (payload) { return new app_reducer_1.ReportDetailLoaded(payload); }));
        //
    }
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "translationsLoad$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "configurationLoaded$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "logout$", void 0);
    __decorate([
        effects_1.Effect({ dispatch: false }),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "createExport$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "reportsLoad$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "searchLoad$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "auditsLoad$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "dashboardLoad$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "dashboardProjectTagAttach$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "dashboardProjectTagDetach$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "calendarLoad$", void 0);
    __decorate([
        effects_1.Effect(),
        __metadata("design:type", Object)
    ], AppEffects.prototype, "reportDetailLoad$", void 0);
    return AppEffects;
}());
exports.AppEffects = AppEffects;
